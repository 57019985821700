<template>
    <div class="mx-auto container p-4 pb-16">
        <div class="max-w-base mx-auto">
            <div class="mb-3 text-center">
                <p class="courses__title" v-text="$t('COURSES.PAGE_TITLE')"/>
                <p class="courses__subTitle" v-text="$t('COURSES.PAGE_SUBTITLE')"/>
            </div>
        </div>
        <div class="w-full">
            <loading-capsule
                :loaded="coursesHaveFetched"
                spinner-margin-top="10rem">
                <div class="mx-auto flex flex-wrap justify-center max-w-xs"
                     v-if="coursesHaveFetched">
                    <card
                        class="exerciseCard--multipleChoiceWithImages cursor-pointer m-1"
                        v-for="course in courses"
                        @click="updateActiveCourse(course.id)"
                        :image-src="course.entity.hasImage() ? course.entity.getImageSrc() : null">
                        <span v-text="getTranslatedNameForCourse(course)"/>
                    </card>
                </div>
                <div v-else>
                    <p>Sorry, there are no courses currently available!</p>
                </div>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import VuexUtils from '@/mixins/VuexUtils';
    import Card from '@/components/partials/Card';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';

    export default {
        name      : 'Courses',
        mixins    : [VuexUtils],
        created   : function () {
            if (false === this.userProfileIsLoaded) {
                this.$router.push({name: 'auth.login'});
            } else {
                this.$store.dispatch('Language/fetchLanguages');
            }
        },
        methods   : {
            /**
             * Update the active course for the user.
             *
             * @param {String} uuid
             *
             * @return {void}
             */
            updateActiveCourse(uuid) {
                const payload = {
                    active_course_uuid: uuid,
                };

                this.$http
                    .patch(`api/users/${this.userUuid}`, payload)
                    .then(response => {
                        this.$store.dispatch('Account/setUserData', response.data.data)
                            .then(() => {
                                this.resetMainVuexContentStores();
                                this.$router.push({name: 'app.dashboard'});
                            });
                    })
                    .catch(error => console.error(error));
            },
            /**
             * Get the translated name for the user's interface language for the given course,
             * if available.
             *
             * @param {*} course
             *
             * @return {String}
             */
            getTranslatedNameForCourse(course) {
                return course.entity.getTranslationFor(this.interfaceIso)
                    || course.name;
            },
        },
        computed  : {
            ...mapGetters({
                userUuid           : 'Account/getUuid',
                userProfileIsLoaded: 'Account/getUserProfileLoaded',
                learningIso        : 'Account/getLearningIso',
                interfaceIso       : 'Account/getInterfaceIso',
            }),
            coursesHaveFetched() {
                return this.courses && this.courses.length > 0;
            },
            courses: function () {
                return this.$store.getters['Language/getLanguageCourses'](this.learningIso);
            },
        },
        components: {
            LoadingCapsule,
            Card,
        },
    };
</script>
